import { API } from 'aws-amplify';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import Select from 'react-select';

import * as XLSX from 'xlsx';
import { StackedBarChart } from '../../components/Chart';
import { TableClearFilter, TableDateFilter, TableShipperFilter, TimeZoneFilter } from '../../components/TableFilter';
import Unauthorized from '../../components/Unauthorized';
import ReactGA from "react-ga4";
import { handleApiError, isOwner, PAGE_TITLE } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { storeShipper } from '../../stores/slice';


import { hideLoader, showLoader } from '../../components/SwalLoader';
import { UserCarrierFilter } from '../../components/FilterWithIds';

const shipperUsersByShipperId = /* GraphQL */ `
  query ShipperUsersByShipperId( $shipperId: ID! $limit: Int  ) {
    shipperUsersByShipperId( shipperId: $shipperId limit: $limit ) {
      items {
        id
        userId
		user{
			name
		}
      }
    }
  }
`;
const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $filter: ModelDeliveryServiceFilterInput
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      filter: $filter
    ) {
      items {
        id
        shipperId
        carrierId
        carrier{
            id
            name
            timezone {
                alias
                id
                name
            }
        }
      }
      nextToken
      __typename
    }
  }
`;

let pageSize = 1000;

export default function PerformanceDashboard() {
    const dispatch = useDispatch();
    const myShipper = useSelector((state) => state.slice.SHIPPER)
    const myShippers = useSelector((state) => state.slice.SHIPPERS)
    const [chartData, setChartData] = useState({ labels: [], datasets: [] })
    const [shipmentTableData, setShipmentTableData] = useState({})
    const [users, setUsers] = useState([])
    const [user, setUser] = useState(null)
    const [shipments, setShipments] = useState([])
    const [resetDate, setRestDate] = useState(0);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [carrier, setCarrier] = useState(null)
    const [carrierList, setCarrierList] = useState([])
    const [tz, setTz] = useState("EST");
    const [dateFilters, setDateFilters] = useState({
        fromDate: moment().tz(timeZone)?.startOf('day').unix(),
        toDate: moment(timeZone)?.endOf('day').unix()
    });

    const getServices = () => {
        myShipper?.shipper?.id && API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.shipper?.id } }).then((response) => {
            const deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));

            let carriers = [];
            deliveryServices.forEach((item) => {
                if (!carriers.find(x => x?.id === item?.carrier?.id))
                    carriers.push(item.carrier)
            });

            carriers = carriers.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
            setCarrierList(carriers)
        })
    }

    function filterShipment(shipments) {
        if (user?.value) shipments = shipments.filter((item) => item.history.items.find(item => item.status === 'READY_FOR_PICKUP').userId === user?.value)
        if (shipments.length === 0) {
            setChartData({ labels: [], datasets: [] })
            setShipmentTableData({})
            return toast.error("No Data Found!")
        }
        let startTime = +shipments[0].readyForPickupTime.slice(0, 1)
        let timebyHours = getHourlyTimestamps(startTime, dateFilters?.fromDate)
        let shipmentsByUser = Object.groupBy(shipments, ({ username }) => username);

        let finalData = {}
        for (let item in shipmentsByUser) {
            if (item !== 'undefined') {
                for (let i = 0; i < timebyHours.length; i++) {
                    if (!finalData[item]) finalData[item] = [item]
                    finalData[item].push(shipmentsByUser[item]?.filter(item => item.readyForPickupTime && item.readyForPickupTime.includes(timebyHours[i])).length)
                }
            }

        }
        let sumByHour = 0;
        for (let item in finalData) {
            let total = 0;
            for (let num of finalData[item]) {
                if (Number(num)) total += +num
            }
            sumByHour += total
            finalData[item].push(total)
        }
        let labels = []
        let datasets = []

        for (let i = 0; i < timebyHours.length; i++) {
            labels.push(timebyHours[i])
            datasets.push(shipments?.filter(item => item.readyForPickupTime && item.readyForPickupTime.includes(timebyHours[i])).length)
        }

        finalData["Total"] = ["Total", ...datasets, sumByHour]
        for (let item in finalData) {
            finalData[item].push(`${((finalData[item].at(-1) / +sumByHour) * 100).toFixed(2)} %`)
        }
        setChartData({ labels, datasets })
        setShipmentTableData(finalData)
    }
    async function getShipment() {
        if (myShipper.role !== 'OWNER') return
        showLoader()
        try {
            const apiName = 'api';
            const path = `/search/shipment?size=${0}&from=${0}`;
            let init = {
                body: {
                    sort: [
                        {
                            "readyForPickupTime": {
                                "order": "asc",
                                "unmapped_type": "date"
                            }
                        }
                    ],
                    query: {
                        bool: {
                            must: [{
                                range: {
                                    "readyForPickupTime": {
                                        "gte": dateFilters?.fromDate,
                                        "lte": dateFilters?.toDate
                                    }
                                }
                            }],
                            must_not: [{ match: { status: "OPEN" } }]
                        }
                    }
                }
            }
            if (carrier) init.body.query.bool.must.push({ match: { "carrierId": carrier?.id } });
            if (myShipper?.shipper) init.body.query.bool.must.push({ match: { "shipper.name.keyword": myShipper?.shipper?.name } })
            else if (myShippers) {
                let shipperIdArr = []
                myShippers.forEach((item) => {
                    shipperIdArr.push(item.shipper.id)
                })
                init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
            }
            let data = await API.post(apiName, path, init);
            let page = 0;
            let apiArgs = []
            while (page < Math.ceil(data.hits.total.value / pageSize)) {
                const path = `/search/shipment?size=${pageSize}&from=${page * pageSize}`;
                apiArgs.push({ apiName, path, init });
                page++;
            }
            let x = apiArgs.map(call => API.post(call.apiName, call.path, call.init));
            Promise.all(x).then(res => {
                let shipmentList = []
                for (let items of res) {
                    const sourceData = items?.hits?.hits?.map((item) => item?._source)
                    shipmentList.push(...sourceData)
                }

                for (let item of shipmentList) {
                    item.username = item?.history.items.find(item => item.status === 'READY_FOR_PICKUP')?.user?.name
                    if (item.readyForPickupTime) item.readyForPickupTime = moment.unix(item.readyForPickupTime).format("h a")
                }
                setShipments(shipmentList)

                if (shipmentList.length === 0) {
                    hideLoader()
                    setChartData({ labels: [], datasets: [] })
                    setShipmentTableData({})
                    return toast.error("No Data Found!")
                }
                hideLoader()
            })
        } catch (error) {
            handleApiError(error)
            hideLoader()
        }
    }

    function exportToCSV(chartData, shipmentTableData) {
        const wsData = [
            ['Name', ...chartData.labels, 'Total', 'Total(%)']
        ];

        Object.values(shipmentTableData).forEach(row => {
            const newRow = row.map(cell => {
                return cell || '_';
            });
            wsData.push(newRow);
        });

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        XLSX.writeFile(wb, 'performance_data.csv');
    }

    function getHourlyTimestamps(startHour, dateTimestamp) {
        const endHour = 18;
        const timestamps = [];

        const date = moment.unix(dateTimestamp);

        for (let hour = startHour; hour <= endHour; hour++) {
            const timestamp = date.clone().hour(hour).minute(0).second(0).millisecond(0).format("h a");
            timestamps.push(timestamp);
        }

        return timestamps;
    }

    const getUsers = () => {
        if (myShipper?.shipper?.id) {
            API.graphql({ query: shipperUsersByShipperId, variables: { shipperId: myShipper.shipper.id, limit: 10000 } })
                .then((res) => setUsers(res?.data?.shipperUsersByShipperId?.items.filter(item => item.user !== null).sort((a, b) => a?.user?.name.localeCompare(b?.user?.name, undefined, { sensitivity: 'accent' }))))
                .catch((error) => handleApiError(error))
        } else {
            const promises = [];
            if (!isEmpty(myShipper)) {
                for (let item of myShipper) {
                    const promise = API.graphql({ query: shipperUsersByShipperId, variables: { shipperId: item?.shipper?.id, limit: 10000 } })
                        .then((res) => {
                            return res.data.shipperUsersByShipperId.items;
                        });
                    promises.push(promise);
                }
                Promise.all(promises)
                    .then((results) => {
                        const temp = results.flat();
                        setUsers(temp.sort((a, b) => a.user.name.localeCompare(b.user.name, undefined, { sensitivity: 'accent' })))
                    })
                    .catch((error) => {
                        handleApiError(error)
                    });
            }
        }
    }

    useEffect(() => getUsers(), [myShipper])

    useEffect(() => {
        if (myShipper?.shipper || myShippers?.length !== 0) {
            getShipment()
        }
    }, [dateFilters, myShipper, myShippers, carrier])

    useEffect(() => {
        if (carrier?.timezone?.id) {
            setTimeZone(carrier?.timezone?.id)
            setTz(carrier?.timezone?.alias)
        } else if (myShipper?.shipper) {
            setTimeZone(myShipper?.shipper?.timezone?.id)
            setTz(myShipper?.shipper?.timezone?.alias)
        }

    }, [myShipper, myShippers, carrier])

    useEffect(() => {
        getServices()
    }, [myShipper])


    useEffect(() => {
        document.title = `Staff Performance ${PAGE_TITLE}`;
        ReactGA.send({
            hitType: "pageview",
            page: "/dashboard/staff-performance",
        })
    }, [])
    const clearFilters = () => {
        setCarrier(null)
        setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
        setDateFilters({
            fromDate: moment().tz(myShipper?.shipper?.timezone?.id || 'America/New_York')?.startOf('day').unix(),
            toDate: moment()?.endOf('day').unix()
        });
        setUser(null)
        setTimeZone("America/New_York");
        setTz("EST");
    }

    useEffect(() => {
        if (shipments.length !== 0) filterShipment(shipments)
    }, [user?.value, shipments])

    const tzHandle = (e) => {
        setTimeZone(e);
    };
    return (
        <div>
            <div className='header' style={{ background: "#f9fbfd" }}>
                <Container fluid>
                    <div className='header-body'>
                        <Row className='align-items-center'>
                            <Col>
                                <h6 className='header-pretitle'>{myShipper?.shipper?.name}</h6>
                                <h1 className='header-title text-dark'>Staff Performance</h1>
                            </Col>
                            <TableShipperFilter hideAll={true} />
                            <UserCarrierFilter onChange={setCarrier} value={carrier} carrierList={carrierList} />
                            <Col className='col-auto px-1 my-1'>
                                <Select
                                    placeholder='Select User'
                                    options={users.map(item => {
                                        return { label: item?.user?.name, value: item?.userId }
                                    })}
                                    isClearable
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: '0.8125rem',
                                            color: '#283E59',
                                            backgroundColor: '#EDF2F9',
                                            borderWidth: '0px',
                                            minHeight: '30px',
                                            minWidth: '120px'
                                        }),
                                    }}
                                    value={user ? user : null}
                                    classNamePrefix='phox-as-sm'
                                    onChange={(e) => {
                                        setUser(e)
                                    }}
                                />
                            </Col>
                            <TableDateFilter key={resetDate} dateFilters={dateFilters} timezone={myShipper?.shipper?.timezone?.id} onChange={setDateFilters} />
                            {isOwner(myShipper?.role) &&
                                <Col className='col-auto px-1 my-1'>
                                    <Button className='btn btn-sm btn-light' onClick={() => exportToCSV(chartData, shipmentTableData)}>Export</Button>
                                </Col>
                            }
                            <TimeZoneFilter
                                title={""}
                                setTimeZone={setTimeZone}
                                dark={true}
                                onChange={tzHandle}
                                tz={tz}
                                setTz={setTz}
                            />
                            <TableClearFilter onClick={clearFilters} />
                        </Row>
                    </div>
                    {myShipper.role !== 'OWNER' ? <Unauthorized /> : (
                        <div className='header-footer'>
                            <StackedBarChart data={chartData} />
                        </div>
                    )}
                </Container>
            </div>
            {myShipper.role === 'OWNER' && (
                <Container fluid>
                    <Card>
                        <Table responsive size='sm' className='mb-0' >
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {chartData.labels.map(item => (
                                        <th className='text-center'>{item}</th>
                                    ))}
                                    <th className='text-center'>Total</th>
                                    <th className='text-center'>Total(%)</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.values(shipmentTableData).map((item, idx) => (
                                    <tr style={{ background: idx === Object.values(shipmentTableData).length - 1 && "#f9fbfd" }}>
                                        <>
                                            {item.map((num, idx) => (
                                                <td align={idx !== 0 ? 'center' : 'left'} style={{ background: idx >= item.length - 2 && "#f9fbfd" }}><span className={
                                                    Math.max(...item.slice(1, -2)) === num && idx < item.length - 2 && num !== 0
                                                        ? 'bg-success p-2 text-white'
                                                        : (Math.min(...item.slice(1, -2).filter(total => total !== 0)) === num && num !== 0 && idx < item.length - 2)
                                                            ? 'bg-danger p-2 text-white'
                                                            : ""
                                                }>{num || "_"}
                                                </span></td>
                                            ))}
                                        </>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                </Container>
            )}
        </div>
    )
}
