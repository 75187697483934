/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PatientActiveFilter, TableKeywordSearch, TablePagination, TableShipperFilter, TableShipperGroupFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, handleApiError, isOwner, PAGE_SIZE } from '../../helpers';
import { isEmpty } from 'lodash';
import ReactGA from "react-ga4"

const UserList = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/user",
		})
	}, [])

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);
	const navigate = useNavigate()

	const [users, setUsers] = useState([]);
	const [spinner, showSpinner] = useState(true);
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [active, setActive] = useState("")

	useEffect(() => {
		const delay = setTimeout(() => {
			setPageNumber(0);
			if (myShipperGroup?.shipperGroup?.name || myShipper?.shipper?.name || myShippers?.length > 0) {
				getUsers()
			}
		}, DELAY_TIMEOUT);
		return () => clearTimeout(delay);
	}, [myShipper, myShipperGroup, keyword, active])

	useEffect(() => {
		if (myShipperGroup?.shipperGroup?.name || myShipper?.shipper?.name || myShippers?.length > 0) {
			getUsers()
		}
	}, [pageNumber])


	const getUsers = async () => {
		showSpinner(true);
		let shipperNameArray = []
		try {
			const apiName = 'api';
			const path = `/search/user?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				"body": {
					"query": {
						"bool": {
							"must": []
						}
					}
				}
			}
			if (myShipperGroup?.shipperGroup?.name && !myShipper?.shipper?.name) {
				try {
					let apiName = "api";
					let path = "/search/shipper?size=1000";
					let init = {
						body: {
							query: {
								bool: {
									must: [
										{
											match: {
												"shipperGroupId.keyword": myShipperGroup?.shipperGroup?.id
											}
										}
									]
								}
							}
						}
					}
					const data = await API.post(apiName, path, init);
					shipperNameArray = data?.hits?.hits?.map((shipper) => shipper?._source?.name)
				} catch (error) {
					handleApiError(error)
				}

				init.body.query.bool.must.push({
					terms: {
						"shippers.shipper.name.keyword": shipperNameArray
					}
				})
			}


			if (myShipper?.shipper?.name) {
				init.body.query.bool.must.push({
					terms: {
						"shippers.shipper.name.keyword": [myShipper?.shipper?.name]
					}
				})
			} else if (!myShipperGroup?.shipperGroup?.name && myShippers?.length > 0) {
				let shippers = myShippers?.map((x) => x.shipper.name)
				init.body.query.bool.must.push({
					terms: {
						"shippers.shipper.name.keyword": shippers
					}
				})
			}

			if (keyword) {
				let fields = [
					"name",
					"email"
				]
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (active === true) init.body.query.bool.must.push({ match: { active: active } })
			else if (active === false) init.body.query.bool.must.push({ match: { active: active } })
			const data = await API.post(apiName, path, init);
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			let userData = data?.hits?.hits?.map((x) => x?._source)
			setUsers(userData);
			showSpinner(false)
		} catch (error) {
			handleApiError(error)
		}
	}

	return (
		<>
			<PageHeader name='Users' pageTitle={"User List"}>
				{isOwner(myShipper?.role) && <Link className='btn btn-dark ms-2' to='/user/invite'>Invite User</Link>}

				<Button className='btn btn-light ms-2' onClick={() => { getUsers() }}>
					<i className='fe fe-refresh-cw'></i>
				</Button>
			</PageHeader>

			<Container fluid>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							{myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
							<TableShipperFilter />
							<PatientActiveFilter active={active} onChange={setActive} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th className='text-center'>Role</th>
									<th>Status </th>
								</tr>
							</thead>
							<tbody>
								{
									users && users?.map((user, index) => {

										let userRole;
										if (myShipper?.shipper?.name) {
											userRole = user?.shippers?.filter((shipper) => myShipper?.shipper?.name == shipper?.shipper?.name)[0]?.role
										}

										return (
											<tr key={index} onClick={() => { (isOwner(myShipper?.role) || myShipperGroup?.shipperGroup?.name) && navigate(`/user/${user?.id}/overview`) }} style={{ cursor: 'pointer' }}>
												<td>
													{user?.name}
													<div className='small text-muted'>
														{user?.title} {user?.company}
													</div>
													<div className='small text-muted'></div>
													<div className='small text-muted'></div>
												</td>
												<td>{user?.email}</td>
												<td className='text-center'>
													{userRole ? <span className='text-capitalize bg-light p-2 rounded'>{userRole}</span> : "-"}
												</td>
												<td>
													{
														user?.active ?
															<span className={`badge p-2 bg-success`}>Active</span>
															: <span className={`badge p-2 bg-danger-soft`}>Disabled</span>
													}
												</td>
											</tr>
										)
									})
								}
							</tbody>
							<TablePagination
								pageNumber={pageNumber}
								pageCount={pageCount}
								setPageNumber={setPageNumber}
								colSpan={8}
							/>
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	)
}

export default UserList;
