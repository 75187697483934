import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { PAGE_TITLE } from '../helpers';

const PageHeader = ({ title, name, menus, children, className, pageTitle, suffix }) => {
	document.title = `${pageTitle ? pageTitle : name} ${PAGE_TITLE}`;
	const myShipper = useSelector((state) => state.slice.SHIPPER)

	return (
		<nav className='header'>
			<div className={className ? className : 'container-fluid'}>
				<div className='header-body'>
					<Row className='align-items-center'>
						<Col>
							<h6 className='header-pretitle'>{title || myShipper?.shipper?.name || 'Phox Health'}</h6>
							<h1 className='header-title'>{name}</h1>
							{suffix &&
								<h5 className="header-pretitle mt-3 mb-0">{suffix}</h5>}

							{
								menus && <>
									<ul className='nav nav-tabs nav-overflow header-tabs'>
										{
											menus.map((menu, index) => {
												return (
													<li key={index} className='nav-item'>
														<Link to={menu.url} className={`nav-link pb-3 pt-4 ${menu.active ? 'active' : ''}`}>
															{menu.name}
														</Link>
													</li>
												)
											})
										}
									</ul>
								</>
							}
						</Col>

						{children && <div className='col-auto text-right'> {children}</div>}
					</Row>
				</div>
			</div>
		</nav>
	);
}

export default PageHeader;
