import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, FormGroup, Row, Table } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import uniqid from 'uniqid';
// import { createInviteUser } from '../../components/AuthLayout';
import { useSelector } from 'react-redux';
import FormLabel from '../../components/FormLabel';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { FormError, handleApiError, isEditor, isOwner, isValidEmail, isValidPhone, isViewer } from '../../helpers';
import ReactGA from "react-ga4"

const usersByEmailQuery = /* GraphQL */ `
  query UsersByEmail($email: AWSEmail!) {
    usersByEmail(email: $email) {
      items {
        id
      }
    }
  }
`;

const createInviteUserMutation = /* GraphQL */ `
  mutation CreateInviteUser($input: CreateInviteUserInput!) {
    createInviteUser(input: $input) {
      id
    }
  }
`;


const UserInvite = () => {

    const myShippers = useSelector((state) => state.slice.SHIPPERS)

    const navigate = useNavigate()
    const [spinner, showSpinner] = useState(false);

    const [search, setSearch] = useState(true);
    const [user, setUser] = useState({ email: '', active: true, role: { shipper: null } });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/user/invite",
        })
    }, [])

    const isFormValid = () => {
        const error = {};
        if (!user?.email) error.email = 'Please enter a valid email'
        if (!isValidEmail(user?.email?.trim())) error.email = 'Please enter a valid email'
        if (!isValidPhone(user.phone)) error.phone = 'Please enter a valid phone number'

        if (user.role.shipperGroup?.filter(x => x.id === 'NEW').length > 0) {
            toast.error('Please select a valid Shipper Group');
            error.shipperGroup = 'Please select a valid Shipper Group';
        }

        if (user.role.shipper?.filter(x => x.id === 'NEW').length > 0) {
            toast.error('Please select a valid Shipper');
            error.shipperGroup = 'Please select a valid Shipper';
        }

        if (user.role.carrier?.filter(x => x.id === 'NEW').length > 0) {
            toast.error('Please select a valid Courier');
            error.shipperGroup = 'Please select a valid Courier';
        }

        setErrors(error);
        return Object.keys(error).length === 0;
        // return false
    };

    const createInviteUser = (input) => API.graphql({ query: createInviteUserMutation, variables: { input: input } });

    const sendInvite = async (e) => {
        e.preventDefault();
        if (isFormValid()) {
            showSpinner(true);
            if (!user.role.shipper) {
                toast.error('User role is required.');
                showSpinner(false);
                return; 
            }
            try {
                let input = {
                    id: uniqid(),
                    email: user.email,
                    phone: user.phone,
                    role: JSON.stringify(user.role),
                    active: true
                }
                await createInviteUser(input);
                toast.success(`Invite has been sent to ${user.email}`)
                navigate('/user')
            } catch (error) {
                handleApiError(error.message)
            }
            showSpinner(false);
        }
    };

    const searchUser = async () => {
        try {
            if (isFormValid()) {
                showSpinner(true)
                const apiName = 'api';
                const path = `/search/invite-user`;

                let search = {
                    body: {
                        query: { 
                            bool: {
                                must: [{ match: { 'email.keyword': user.email } }],
                            }
                        }
                    }
                };
                let { hits } = await API.post(apiName, path, search);

                const { data } = await API.graphql({ query: usersByEmailQuery, variables: { email: user.email } });
                if (hits.total.value === 0 && data.usersByEmail.items.length === 0) {
                    setSearch(false);
                } else if (hits.total.value > 0 && hits.hits[0]._source.active) {
                    setSearch(false);
                } else if (data.usersByEmail.items.length > 0) {
                    navigate(`/user/${data.usersByEmail.items[0].id}/overview`);
                } else {
                    toast.error('Oops! Looks like there was an error');
                }
                showSpinner(false)
            }
        } catch (error) {
            console.errpr(error)
            setErrors((prev) => ({ ...prev, email: 'Please enter a valid email' }))
        } finally { showSpinner(false) }
    }

    //#region Shipper
    const searchShipper = async (keyword) => {
        let items = []
        myShippers.forEach(item => {
            if (item?.role === 'OWNER') items.push({
                label: item.shipper.name,
                value: item.shipper.id
            })
        });

        let shipperIDs = user?.role?.shipper?.map(item => item.id);
        let shipperList = items.filter(item => !shipperIDs?.includes(item.value));

        if (keyword) {
            shipperList = shipperList.filter(item => item.label.toLowerCase().includes(keyword.toLowerCase()))
            return shipperList;
        } else { return shipperList; }
    };

    const addShipperRole = (e) => {
        let shipper = user.role?.shipper ? [...user.role.shipper] : [];
        shipper.push({
            id: 'NEW',
            name: '',
            role: 'VIEWER'
        })
        setUser({ ...user, role: { ...user.role, shipper: shipper } });
    }

    const removeShipperRole = (index) => {
        let shipper = user.role?.shipper ? [...user.role.shipper] : [];
        shipper.splice(index, 1);
        setUser({ ...user, role: { ...user.role, shipper: shipper } });
    }

    const handleShipperRoleChange = (index, event, name) => {
        let shipper = user.role?.shipper ? [...user.role.shipper] : [];

        let accessIds = shipper?.map((item) => item?.id);
        if (accessIds?.includes(event?.value)) {
            toast.error("Shipper is already selected. Please choose a different one.")
        } else {
            if (name === 'id') {
                shipper[index].id = event.value;
                shipper[index].name = event.label;
            }
            if (name === 'role') shipper[index].role = event;
            setUser({ ...user, role: { ...user.role, shipper: shipper } });
        }
    }
    //#endregion



    return (
        <>
            <PageHeader name='Invite User' className='container' pageTitle={"Invited User List"} />
            <Spinner display={spinner}>
                <Container>
                    {
                        search === true && <section id='search'>
                            <FormGroup className='mt-4'>
                                <FormLabel required>Email</FormLabel>
                                <Form.Control type='email' name='email' placeholder='e.g. john@email.com' onChange={(e) => setUser({ ...user, email: e.target.value.toLowerCase() })} value={user?.email} />
                                <FormError error={errors?.email} />
                            </FormGroup>
                            <Button className='btn btn-dark mt-3' onClick={searchUser}>Continue</Button>
                        </section>
                    }

                    {
                        search === false && <>
                            <section id='roles'>
                                <Row>
                                    <Col>
                                        <FormGroup className='mt-3'>
                                            <FormLabel required>Email</FormLabel>
                                            <Form.Control type='email' name='email' placeholder='e.g. john@email.com' onChange={(e) => setUser({ ...user, email: e.target.value })} value={user?.email} disabled />
                                            <FormError error={errors?.email} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </section>


                            <section id='shipper' className='mt-4'>
                                <Card>
                                    <Card.Header>
                                        <Row className='align-items-center'>
                                            <Col>Shipper Role</Col>
                                            <Col className='text-end'>
                                                <Button variant='outline-secondary' size='sm' onClick={addShipperRole}><i className='fe fe-plus mx-2'></i>Add More</Button>
                                            </Col>
                                        </Row>
                                    </Card.Header>
                                    <Card.Body className='p-0'>
                                        <Table className='table-sm mb-0'>
                                            <thead>
                                                <tr>
                                                    <th className='text-start'>Name</th>
                                                    <th>Role</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody className='list font-size-base'>
                                                {
                                                    user.role?.shipper?.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>
                                                                <AsyncSelect key={index} value={{ label: item.name || 'Choose Shipper', value: item.id }}
                                                                    type='text' loadOptions={searchShipper} defaultOptions
                                                                    onChange={(e) => handleShipperRoleChange(index, e, 'id')} />
                                                            </td>
                                                            <td>
                                                                <button className={`btn btn-${isOwner(user.role?.shipper[index].role) ? 'success' : 'light'} me-2`}
                                                                    onClick={() => handleShipperRoleChange(index, 'OWNER', 'role')}>
                                                                    Owner
                                                                </button>

                                                                <button className={`btn btn-${isEditor(user.role?.shipper[index].role) ? 'success' : 'light'} me-2`}
                                                                    onClick={() => handleShipperRoleChange(index, 'EDITOR', 'role')}>
                                                                    Editor
                                                                </button>

                                                                <button className={`btn btn-${isViewer(user.role?.shipper[index].role) ? 'success' : 'light'} me-2`}
                                                                    onClick={() => handleShipperRoleChange(index, 'VIEWER', 'role')}>
                                                                    Viewer
                                                                </button>
                                                                <button className='btn btn-light' onClick={() => removeShipperRole(index)}>
                                                                    <i className='fe fe-trash'></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </section>

                            <div className='mt-3'>
                                <Button type='submit' className='btn btn-dark' onClick={sendInvite}>Send Invite</Button>
                            </div>
                        </>
                    }
                </Container>
            </Spinner>
        </>
    );
};

export default UserInvite;
