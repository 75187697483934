/* eslint-disable react-hooks/exhaustive-deps */
import { API } from "aws-amplify";
import moment from "moment";
import React, { useEffect, useState } from "react";
import BarcodeReader from "react-barcode-reader";
import { Card, Container, Row, Table } from "react-bootstrap";
import ReactGA from "react-ga4";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { StatCard } from "../../components/Card";
import { DriverFilter, ServiceTypeFilter, UserCarrierFilter } from "../../components/FilterWithIds";
import PageHeader from "../../components/PageHeader";
import Spinner from "../../components/Spinner";
import { StatusBadge } from "../../components/StatusBadge";
import {
	GreenPhoxFilter,
	StatusFilter,
	TableClearFilter,
	TableDateFilter,
	TableExportFilter,
	TableKeywordSearch,
	TablePagination,
	TableShipperFilter,
	TableShipperGroupFilter,
	TimeZoneFilter
} from "../../components/TableFilter";
import {
	DELAY_TIMEOUT,
	handleApiError,
	isOwner,
	PAGE_SIZE,
	toLocalDate,
	toLocalTime
} from "../../helpers";
import { storeShipper } from '../../stores/slice';

const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        default
        sort
        shipperId
        carrierId
        carrier{
            id
            name
            alias
            image
			active
			 timezone {
          alias
          id
          name
        }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const exportShipmentData = /* GraphQL */ `
      mutation ExportShipmentData($input: AWSJSON!) {
        exportShipmentData(input: $input)
      }
    `;

const ShipmentList = () => {
	const dispatch = useDispatch();

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);

	const [resetDate, setRestDate] = useState(0);
	const [spinner, showSpinner] = useState(false);
	const [shipments, setShipments] = useState([]);
	const [facets, setFacets] = useState({});
	const [statusCount, setStatusCount] = useState();

	const [keyword, setKeyword] = useState("");
	const [status, setStatus] = useState();

	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();

	const [deliveryService, setDeliveryService] = useState({});
	const [deliveryServicesList, setDeliveryServicesList] = useState([]);
	const [carrier, setCarrier] = useState({})
	const [carrierList, setCarrierList] = useState([])
	const [driver, setDriver] = useState();
	const [isGreenPhox, setIsGreenPhox] = useState();
	const [timeZone, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment()
			.tz(timeZone || "America/New_York")
			?.startOf("day")
			.unix(),
		toDate: moment()
			.tz(timeZone || "America/New_York")
			?.endOf("day")
			.unix(),
	});

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/shipment",
		})
	}, [])

	useEffect(() => {
		if (myShipper?.shipper?.id || myShippers?.length > 0) {
			getElasticShipment();
		}
		if (pageNumber) { setPageNumber(0) };
	}, [status, myShipper, myShipperGroup, carrier, deliveryService, isGreenPhox, driver]);

	useEffect(() => {
		if (myShipper?.shipper?.id || myShippers?.length > 0) {
			getElasticShipment();
		}
	}, [pageNumber]);


	useEffect(() => {
		if (myShipper?.shipper?.id) { getServices() }
	}, [myShipper, myShipperGroup])

	useEffect(() => {
		if (carrier?.timezone?.id) {
			setTimeZone(carrier?.timezone?.id)
			setTz(carrier?.timezone?.alias)
		} else if (myShipper?.shipper) {
			setTimeZone(myShipper?.shipper?.timezone?.id)
			setTz(myShipper?.shipper?.timezone?.alias)
		} else if (myShipperGroup?.shipperGroup?.timezone?.id) {
			setTimeZone(myShipperGroup?.shipperGroup?.timezone?.id)
			setTz(myShipperGroup?.shipperGroup?.timezone?.alias)
		}

	}, [myShipper, myShipperGroup, carrier])

	useEffect(() => {
		const delay = setTimeout(() => {
			setPageNumber(0);
			if (myShipper?.shipper?.id || myShippers?.length > 0) {
				getElasticShipment();
			}
		}, DELAY_TIMEOUT);
		return () => clearTimeout(delay);
	}, [keyword, dateFilters]);

	const getServices = () => {
		setCarrier(null)
		myShipper?.shipper?.id && API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.shipper?.id } })
			.then((response) => {
				const deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));
				setDeliveryServicesList(deliveryServices)

				let carriers = [];
				deliveryServices.forEach((item) => {
					if (!carriers.find(x => x?.id === item?.carrier?.id))
						carriers.push(item.carrier)
				});

				carriers = carriers.filter((carrier) => carrier?.active !== false).sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
				setCarrierList(carriers)
			})
			.catch((error) => handleApiError(error))
	}

	const getElasticShipment = async () => {
		showSpinner(true);
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					aggs: {
						status: {
							terms: {
								"field": "status.keyword",
								"size": 100
							}
						}
					},
					sort: [
						{ "_score": { "order": "desc" } },
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: [],
							must_not: []
						}
					},
				}
			};


			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"expectedDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"extId",
					"customer.extId",
					"shipTo.address.address1",
					"shipTo.address.postalCode"
				]
				if (+keyword) {
					fields.push("number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
				if (keyword.includes("-")) { // RX number includes '-'
					init.body.query.bool.must.pop()
					init.body.query.bool.filter = {
						"terms": {
							"items.number.keyword": [keyword]
						}
					}
				}
			}

			if (myShipperGroup?.shipperGroup?.id) init.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })

			if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}

			if (carrier?.id) init.body.query.bool.must.push({ match: { carrierId: carrier?.id } })
			if (driver) {
				if (driver?.id == "driverNotAssigned") {
					init.body.query.bool.must_not.push({ exists: { field: "driver.name" } })
				} else { init.body.query.bool.must.push({ match: { driverId: driver?.id } }) }
			}
			if (deliveryService?.id) init.body.query.bool.must.push({ match: { deliveryServiceId: deliveryService?.id } })
			if (status) init.body.query.bool.must.push({ match: { status: status } });

			if (isGreenPhox?.value === false) init.body.query.bool.must.push({ match: { isGreenPhox: isGreenPhox?.value } })
			else if (isGreenPhox?.value === true) init.body.query.bool.must.push({ match: { isGreenPhox: isGreenPhox?.value } })

			const data = await API.post(apiName, path, init);

			let statusCount = {};
			data.aggregations.status.buckets?.forEach((item) => {
				statusCount[item.key] = item.doc_count
			})

			setFacets(data.aggregations)
			setStatusCount(statusCount)

			setShipments(data?.hits?.hits);
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			showSpinner(false);
		} catch (error) {
			handleApiError(error)
			showSpinner(false);
		}
	}
	const downloadReport = async () => {

		let downloadingToastId;
		try {
			downloadingToastId = toast.loading("Downloading...");
			let shipperId = null;


			if (myShipper?.shipper?.id)
				shipperId = myShipper.shipper.id;
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperFilter = [];
				myShippers.forEach((item) => isOwner(item?.role) && shipperFilter.push(`${item.shipper.id}`));
				shipperId = JSON.stringify(shipperFilter);
			}

			let input = {
				expectedDeliveryTime: {
					fromDate: dateFilters?.fromDate ?? null,
					toDate: dateFilters?.toDate ?? null
				}, shipperId: shipperId,
			}
			if (myShipperGroup?.shipperGroup?.id) input.shipperGroupId = myShipperGroup?.shipperGroup?.id;
			if (carrier?.id) input.carrierId = carrier.id;
			if (status) input.status = status;
			if (deliveryService?.id) input.deliveryServiceId = deliveryService?.id;
			if (driver?.id) input.driverId = driver?.id;
			if (timeZone) input.timezone = timeZone
			if (isGreenPhox?.value === true) input.isGreenPhox = isGreenPhox?.value
			if (isGreenPhox?.value === false) input.isGreenPhox = isGreenPhox?.value

			let query = {
				query: exportShipmentData,
				variables: {
					input: JSON.stringify(input)
				}
			};
			const data = await API.graphql(query);
			const { statusCode } = JSON.parse(data.data.exportShipmentData);

			if (statusCode === 400) {
				throw "Something went wrong while exporting data.";
			}
			const link = JSON.parse(data.data.exportShipmentData).body;
			if (link) {
				const linkTag = document.createElement('a');
				linkTag.href = link;
				linkTag.click();
				linkTag.remove();
				toast.remove(downloadingToastId);
				toast.success("Download finished");
			} else return toast.error("No data found !")

		} catch (error) {
			handleApiError(error);
		} finally { toast.dismiss(downloadingToastId) }
	};


	const clearFilters = () => {
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setStatus();
		setKeyword("");
		setCarrier()
		setCarrierList([])
		setDeliveryService({})
		setDeliveryServicesList([])
		setPageNumber(0);
		setIsGreenPhox(null)
		setDriver("")
		setDateFilters({
			fromDate: moment()
				.tz("America/New_York")
				?.startOf("day")
				.unix(),
			toDate: moment()
				.tz("America/New_York")
				?.endOf("day")
				.unix(),
		});
		setTimeZone("America/New_York");
		setTz("EST");
		dispatch(storeShipper(myShippers))
	};

	//#region scan

	const handleScan = async (data) => {
		let loading = toast.loading("Loading...")
		try {
			let keyword = data;
			if (keyword.startsWith("\\")) {
				keyword = keyword.slice(1, -1)
			}
			if (keyword.indexOf("^") >= 0) keyword = data.split("^")[0];
			if (keyword.indexOf("RXR") >= 0) keyword = keyword.replace("RXR", "");
			let table = "shipment"
			if (!data.includes("^")) table = "customer"
			const apiName = 'api';
			const path = `/search/${table}?size=${PAGE_SIZE}`;
			let init = {
				body: {
					sort: [
						{ "_score": { "order": "desc" } },
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: [{ match: { shipperId: myShipper.shipper.id } }]
						}
					}
				}
			};
			if (!data.includes("^")) init.body.query.bool.must.push({ "match": { "extId": data } })
			else {
				init.body.query.bool.must.push({ "bool": { "should": [{ "match": { "status": "OPEN" } }, { "match": { "status": "READY_FOR_PICKUP" } }] } })
				if (data.split("^").length == 6) {
					data = data.split("^")[0]
					init.body.query.bool["filter"] = { "terms": { "shipTo.name.keyword": [data] } }
				} else { init.body.query.bool["filter"] = { "terms": { "items.number.keyword": [keyword] } } }
			}

			const { hits } = await API.post(apiName, path, init);
			const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];
			if (sourceData.length === 0) return toast.error("No data found !")
			if (table === "shipment") {
				sourceData[0]?.id && window.open(`/shipment/${sourceData[0]?.id}`, "_self");
				localStorage.setItem('enterWeight', true)
			} else {
				sourceData[0]?.id && window.open(`/shipment/create?patient_id=${sourceData[0]?.id}`, "_self");
			}

		} catch (error) {
			handleApiError(error)
		} finally { toast.dismiss(loading) }

	};
	const handleScanError = (error) => {
		handleApiError(error)
	};
	const tzHandle = (e) => {
		setTimeZone(e);
	};

	const checkOwner = (shipper) => {
		if (shipper?.role) {
			return isOwner(shipper?.role)
		} else if (shipper?.length > 1) {
			for (let i = 0; i < shipper?.length; i++) {
				if (isOwner(shipper[i]?.role)) {
					return true
				}
			}
		}
		return false
	}
	//#endregion

	return (
		<>
			<PageHeader name="All Shipments">
				{myShipper?.role !== "VIEWER" && (
					<Link to={"/shipment/create"} className="btn btn-dark">
						Create Shipment
					</Link>
				)}
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard
						title="Ready for Pickup / Open"
						value={(statusCount?.READY_FOR_PICKUP || 0) + (statusCount?.OPEN || 0)}
						icon="box"
					/>
					<StatCard
						title="Out for Delivery"
						value={
							(statusCount?.OUT_FOR_DELIVERY || 0) +
							(statusCount?.IN_TRANSIT || 0)
						}
						icon="truck"
					/>
					<StatCard
						title="Delivered"
						value={statusCount?.DELIVERED || 0}
						icon="check-circle"
					/>
					<StatCard
						title="Exceptions / Returns"
						value={
							(statusCount?.EXCEPTION || 0) +
							(statusCount?.OUT_FOR_RETURN || 0) +
							(statusCount?.RETURNED || 0)
						}
						icon="alert-triangle"
					/>
				</Row>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							{myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
							<TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false} />
							{myShipper?.shipper?.id && <UserCarrierFilter onChange={setCarrier} value={carrier} carrierList={carrierList} />}
							{carrier?.id && <DriverFilter onChange={setDriver} value={driver} shipper={myShipper?.shipper?.id} carrier={carrier} />}
							{carrier?.id && <ServiceTypeFilter carrierId={carrier?.id} onChange={setDeliveryService} value={deliveryService} serviceList={deliveryServicesList} />}

							<StatusFilter onChange={setStatus} value={status} />
							<GreenPhoxFilter value={isGreenPhox} onChange={setIsGreenPhox} />
							<TableDateFilter
								key={resetDate}
								timezone={myShipperGroup?.shipperGroup?.timezone?.id || myShipper?.shipper?.timezone?.id || carrier?.timezone?.id}
								dateFilters={dateFilters}
								onChange={setDateFilters}
							/>
							{checkOwner(myShipper) &&

								<TableExportFilter onChange={downloadReport} option="Download as Excel" />
							}
							<TimeZoneFilter
								title={""}
								setTimeZone={setTimeZone}
								dark={true}
								onChange={tzHandle}
								tz={tz}
								setTz={setTz}
							/>
							<TableClearFilter onClick={clearFilters} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size="sm" className="mb-0">
							<thead>
								<tr>
									<th className="text-center">Shipment #</th>
									{!myShipper?.shipper?.id && <th>Shipper</th>}
									<th>Recipient</th>
									<th>Driver / Carrier</th>
									<th className="text-center">Delivery Service</th>
									<th className="text-center">Entered At</th>
									<th className="text-center">Due By</th>
									<th className='text-center'>Delivered At</th>
									<th className="text-center">Status</th>
								</tr>
							</thead>
							<tbody>
								{shipments.map((shipment) => {
									return (
										<tr key={shipment._source.id} style={{ cursor: "pointer" }}
											onClick={(e) => {
												if (e.metaKey || e.ctrlKey) {
													window.open(`/shipment/${shipment._source?.id}`, '_blank');
												} else {
													window.open(`/shipment/${shipment._source?.id}`, '_self');
												}
											}}>
											<td className="text-center">
												<div>
													{shipment._source?.isGreenPhox ? <img src="img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'20px'} width={'20px'} /> : <small className="ms-5"> </small>}
													{shipment?._source?.number}
												</div>
												{shipment.batch_number > 0 && (
													<div className="small text-muted">
														Batch #{shipment?._source?.batch_number}
													</div>
												)}
											</td>
											{!myShipper?.shipper?.id && (
												<td>{shipment?._source?.shipper?.name}</td>
											)}
											<td>
												{shipment?._source.shipTo?.name}
												<div className="small text-muted">
													{shipment?._source.shipTo?.address?.city},{" "}
													{shipment?._source.shipTo?.address?.state}
												</div>
											</td>
											<td>
												<div>{shipment?._source.driver?.name || "Unassigned"}</div>
												<div className="text-small text-muted">
													{shipment?._source.carrier?.alias}
												</div>
											</td>
											<td className="text-center">
												{shipment?._source.deliveryService?.name}
											</td>
											<td className="text-center">
												{toLocalTime(
													shipment?._source?.createdTime,
													shipment?._source?.shipFrom?.timezone?.id
												)}
												<div className="small text-muted">
													{toLocalDate(
														shipment?._source?.createdTime,
														shipment?._source?.shipFrom?.timezone?.id
													)}
												</div>
											</td>
											<td className="text-center">
												{shipment?._source?.expectedDeliveryTime && (
													<>
														{toLocalTime(
															shipment?._source?.expectedDeliveryTime,
															shipment?._source?.shipFrom?.timezone?.id
														)}
														<div className="small text-muted">
															{toLocalDate(
																shipment?._source?.expectedDeliveryTime,
																shipment?._source?.shipFrom?.timezone?.id
															)}
														</div>
													</>
												)}
											</td>
											<td className='text-center'>
												{
													shipment?._source.actualDeliveryTime ? <>
														{toLocalTime(shipment?._source?.actualDeliveryTime, shipment?._source?.shipFrom?.timezone?.id)}
														<div className='small text-muted'>{toLocalDate(shipment?._source?.actualDeliveryTime, shipment?._source?.shipFrom?.timezone?.id)}</div>
													</> : "-"
												}
											</td>
											<td className="text-center">
												<StatusBadge status={shipment?._source.status} />
											</td>
										</tr>
									);
								})}
							</tbody>
							<TablePagination
								pageNumber={pageNumber}
								pageCount={pageCount}
								setPageNumber={setPageNumber}
								colSpan={8}
							/>
						</Table>
					</Spinner>
				</Card>
			</Container>

			<BarcodeReader onError={handleScanError} onScan={handleScan} />
		</>
	);
};

export default ShipmentList;
